.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.toastr {
  min-height: auto !important;
  right: 10px !important;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.rrt-holder {
  height: 40px !important;
  width: 40px !important;
  top: 0 !important;
  margin-top: 0 !important;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* visibility: hidden !important; */
}
.toastr-icon {
  width: 24px !important;
  height: 24px !important;
  display: none !important;
}
.rrt-middle-container {
  margin-left: 10px !important;
}
input[type="search"],
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
  -moz-appearance: none;
}

.scrollable-on-x {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  scrollbar-width: thin;
  scrollbar-color: #ccc #ccc;
}
.scrollable-on-x::-webkit-scrollbar {
  height: 4px;
}
.scrollable-on-x::-webkit-scrollbar-track {
  background: transparent;
}
.scrollable-on-x::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  border: 3px solid transparent;
}
.scrollable-on-x:hover::-webkit-scrollbar-thumb {
  background-color: #ccc;
}
.scroll-x-hidden {
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
